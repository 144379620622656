const Testimonial = [
    {
        Name: "Aarti",
        Des: "I absolutely love my new Cushion Cover! It instantly transformed my living room with its vibrant color and soft fabric. It fits perfectly on my couch and adds a cozy, stylish touch. The material is durable and easy to clean, making it a great addition to my home décor.",
    },
    {
        Name: "Alpa",
        Des: "I'm so happy with my new Throws! They're incredibly soft and cozy, perfect for snuggling up on chilly evenings. The quality is amazing, and they add a beautiful touch to my sofa. They’re also easy to care for and have quickly become a must-have in my living room.",
    },
    {
        Name: "Rachna",
        Des: "I’m in love with my new Pouffe! It’s not only a stylish addition to my living room but also super functional. It serves as extra seating and a footrest, making it perfect for relaxing. The quality is fantastic, and it fits beautifully with my home décor. Highly recommend!",
    },
    {
        Name: "Madhu",
        Des: "Jutiepie's Ottoman! It’s the perfect combination of style and functionality. Not only does it provide extra seating, but also helps me keep my space organized. The design is sleek and matches my décor beautifully. Definitely a must-have for any home!",
    },
    {
        Name: "Kalpana",
        Des: "These Organizers have completely transformed my space! They’re perfect for keeping everything tidy and in its place. Whether it’s my closet, kitchen, or bathroom, they make organizing so easy. The design is sleek and functional, and they’ve helped me declutter and create a more organized, stress-free home!",
    },
    {
        Name: "Priya",
        Des: "I'm so glad I got this Laundry Bag! It's spacious, durable, and perfect for keeping my laundry organized. The fabric is strong, and it’s easy to carry around. It helps keep my laundry area tidy and makes sorting clothes a breeze. A simple, yet essential, addition to my home!",
    },
]

export default Testimonial;