import React, { useLayoutEffect } from 'react';
import "../Css/AboutUs.css";
import HappyCustomers from "../Images/icons/like-button.png";
import FoundingYear from "../Images/icons/people.png";
import ProductOrders from "../Images/icons/delivery-van.png";
import QualityProducts from '../Images/icons/medal.png';
import About from "../Images/about-01.png";
import ProductData from '../Component/ProductData';
import Footer from "../Component/Footer";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { AiFillHome } from 'react-icons/ai';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import Testimonial from '../Component/Testimonial';
import demotestimonial from "../Images/demo-removebg-preview.png";

const AboutPage = () => {
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });
    return (
        <>

            {/* ---------------- Section 1 ---------------------- */}
            <section className='ProductList-Section-1'>
                <div className='container'>

                    <div className='ProductList-Section-con'>
                        <div className='ProductList-Section-Text'>
                            <h2>
                                About
                            </h2>
                            <div className='ProductList-Section-NavPage'>
                                <span><AiFillHome />Home</span>
                                <h3><MdKeyboardArrowRight /></h3>
                                <h1 className="d-none">Best Salon in Ghatkopar</h1>
                                <h2>About</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            {/* ///////////////// Section 1 ///////////////////// */}

            <section className='AboutUs-Section-1'>
                <div className='container'>
                    <div className='AboutUs-Section-1-con'>
                        <div className='row'>
                            <div className='col-md-4'>
                                <div className='About-Section-1-Image-con'>
                                    <div className='About-Section-1-Image'>
                                        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/XBc5i8HtwNc?autoplay=1&mute=1&si=l800Ft_gOJ29MAzh" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen referrerpolicy="strict-origin-when-cross-origin"></iframe>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-8'>
                                <div className='About-Section-1-Details'>
                                    {/* <div className='About-Section-1-Details-con'>
                                        <h2 className='Smalltitle'>About Us</h2>
                                        <h3 className='Headtitle'>Online shopping includes both buying things online.</h3>
                                        <h4>Shop Easily, Live Stylishly with Eco-Conscious, Artisan-Crafted Products.</h4>
                                        <p>At Jutie Pie, we redefine online shopping by combining convenience with quality. Our easy-to-navigate platform ensures a seamless and enjoyable experience, allowing you to purchase our uniquely crafted bags, tables, and chairs from the comfort of your home. </p>
                                        <p>We're committed to bringing you eco-friendly, stylish products that don't just fill spaces but enrich lives. Explore our diverse range and find everything you need to beautify your surroundings with just a few clicks. With Jutie Pie, discover the true potential of online shopping – simple, secure, and satisfying.</p>
                                    </div> */}
                                    <div className='FAQ-Info'>
                                        <div class="accordion" id="accordionExample">
                                            <div class="accordion-item">
                                                <h2 class="accordion-header" id="headingOne">
                                                    <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                        What is Jute & what is it made of?
                                                    </button>
                                                </h2>
                                                <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                    <div class="accordion-body">
                                                        <p>
                                                            Jute is a long, rough, shiny bast fibre that can be spun into coarse, strong threads. It is produced from flowering plants in the genus Corchorus, of the mallow family Malvaceae. Jute fibre is obtained from the stem of the jute plant.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="accordion-item">
                                                <h2 class="accordion-header" id="headingTwo">
                                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                        What are the uses of Jute?
                                                    </button>
                                                </h2>
                                                <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                                    <div class="accordion-body">
                                                        <p>Jute is a very long fiber that comes from a plant and is used to make rugs, ropes, and other things. Jute twine feels coarse and rough, and is very strong.</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="accordion-item">
                                                <h2 class="accordion-header" id="headingThree">
                                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                        Is Jute environment friendly?
                                                    </button>
                                                </h2>
                                                <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                                    <div class="accordion-body">
                                                        <p>Unlike plastic, which lingers in landfills for centuries, jute if buried decomposes rapidly, leaving behind no toxic residue. The cultivation of jute also has a significantly lower environmental impact compared to cotton, as it requires less water and pesticides</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="accordion-item">
                                                <h2 class="accordion-header" id="headingFour">
                                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                                        Is jute grown in India?
                                                    </button>
                                                </h2>
                                                <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour " data-bs-parent="#accordionExample">
                                                    <div class="accordion-body">
                                                        <p>India is the world's largest producer of raw jute and jute goods mainly from the state of West Bengal. The entire process from growing to harvesting & creating them into finished products is in India supporting Vocal for Local or Made in India initiative. </p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="accordion-item">
                                                <h2 class="accordion-header" id="headingFive">
                                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                                        Does Jute industry support employment ?
                                                    </button>
                                                </h2>
                                                <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                                                    <div class="accordion-body">
                                                        <p>Yes, the jute industry provides employment for over 4 lakh workers. India is the largest producer and exporter of jute products in the world. </p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="accordion-item">
                                                <h2 class="accordion-header" id="headingSix">
                                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                                        To see how is Jute produced from the plant, refer the below video:
                                                    </button>
                                                </h2>
                                                <div id="collapseSix" class="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample">
                                                    <div class="accordion-body">
                                                        <div className='Faqs-video-con'>
                                                            <iframe width="100%" height="100%" src="https://www.youtube.com/embed/XBc5i8HtwNc?si=l800Ft_gOJ29MAzh" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* ///////////////////// Section 2 ///////////////////// */}

            <section className='AboutUs-Section-2'>
                <div className='container'>
                    <div className='AboutUs-Section-Counter'>
                        <div className='row'>
                            <div className='col-lg-3 col-md-6 col-sm-6'>
                                <div className='AboutUs-Section-Counter-card'>
                                    <div className='AboutUs-Section-Counter-Image-con'>
                                        <div className='AboutUs-Section-Counter-Image'>
                                            <img src={HappyCustomers} />
                                        </div>
                                    </div>
                                    <div className='AboutUs-Section-Counter-card-details'>
                                        <h2>1000 <span>+</span></h2>
                                        <h3>Happy Customers</h3>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-3 col-md-6 col-sm-6'>
                                <div className='AboutUs-Section-Counter-card'>
                                    <div className='AboutUs-Section-Counter-Image-con'>
                                        <div className='AboutUs-Section-Counter-Image'>
                                            <img src={FoundingYear} />
                                        </div>
                                    </div>
                                    <div className='AboutUs-Section-Counter-card-details'>
                                        <h2>2022</h2>
                                        <h3>Founding Year</h3>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-3 col-md-6 col-sm-6'>
                                <div className='AboutUs-Section-Counter-card'>
                                    <div className='AboutUs-Section-Counter-Image-con'>
                                        <div className='AboutUs-Section-Counter-Image'>
                                            <img src={ProductOrders} />
                                        </div>
                                    </div>
                                    <div className='AboutUs-Section-Counter-card-details'>
                                        <h2>1000 <span>+</span></h2>
                                        <h3>Product Orders</h3>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-3 col-md-6 col-sm-6'>
                                <div className='AboutUs-Section-Counter-card'>
                                    <div className='AboutUs-Section-Counter-Image-con'>
                                        <div className='AboutUs-Section-Counter-Image'>
                                            <img src={QualityProducts} />
                                        </div>
                                    </div>
                                    <div className='AboutUs-Section-Counter-card-details'>
                                        <h2>60 <span>+</span></h2>
                                        <h3>Quality Products</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            {/* //////////////////////////////// Section 7 ////////////////////// */}

            <section className='Home-Section-7'>
                <div className='container'>
                    <div className='Home-Section-3-head-con'>
                        <h2 className='Smalltitle'>All Product Shop</h2>
                        <h3 className='Headtitle'>Customer Favorite Style Product</h3>
                    </div>
                    <div className='Home-Section-7-testimonial'>
                        <Swiper
                            slidesPerView={1}
                            spaceBetween={10}
                            autoplay={{
                                delay: 2500,
                                disableOnInteraction: false,
                            }}
                            pagination={{
                                clickable: true,
                            }}
                            navigation={true}
                            breakpoints={{
                                640: {
                                    slidesPerView: 2,
                                    spaceBetween: 20,
                                },
                                768: {
                                    slidesPerView: 3,
                                    spaceBetween: 40,
                                },
                                1024: {
                                    slidesPerView: 3,
                                    spaceBetween: 50,
                                },
                            }}
                            modules={[Autoplay, Pagination, Navigation]}
                            className="mySwiper"
                        >
                            {
                                Testimonial.map((elem) => {
                                    const { Name, Des } = elem

                                    return (
                                        <>
                                            < SwiperSlide >
                                                <div
                                                    className="slick-single-layout testimonial-style-one slick-slide slick-current slick-active"
                                                    data-slick-index={0}
                                                    aria-hidden="false"
                                                    tabIndex={0}
                                                // style={{ width: 384 }}
                                                >
                                                    <div className="review-speech">
                                                        <p>
                                                            “{Des}“
                                                        </p>
                                                    </div>
                                                    <div className="media">
                                                        <div className="thumbnail">
                                                            <img
                                                                src={demotestimonial}
                                                                alt="testimonial image"
                                                            />
                                                        </div>
                                                        <div className="media-body">
                                                            {/* <span className="designation">Head Of Idea</span> */}
                                                            <h6 className="title">{Name}</h6>
                                                        </div>
                                                    </div>
                                                    {/* End .thumbnail */}
                                                </div>
                                            </SwiperSlide>
                                        </>
                                    )
                                })
                            }
                        </Swiper>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    )
}

export default AboutPage