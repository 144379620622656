import React, { useEffect, useState } from 'react';
import "../Css/AddtoCart.css";
import { RxCross2 } from "react-icons/rx";
import { Link } from 'react-router-dom';
import { useCartContext } from '../context/addToCart';
import EmptyComp from ".././Component/Empty/empty";

const AddtoCart = () => {
    const { cartValue, setCartCount, setCartValue } = useCartContext();
    const [subTotal, setSubTotal] = useState(0);
    useEffect(() => {
        updateSubtotal(cartValue);
    }, [cartValue]);

    const removeCartData = (index) => {
        setCartValue((prevData) => {
            const updatedData = [...prevData];
            updatedData.splice(index, 1);
            addtoLocatStorage(updatedData);
            setCartCount(updatedData.length);
            return updatedData;
        });
    };

    const updateSubtotal = (data) => {
        const subTotalValue = data.reduce((total, item) => {
            console.log("total",total)
            return total + item.product_price
        }, 0);
        setSubTotal(subTotalValue);
    };

    const addtoLocatStorage = (data) => {
        localStorage.setItem("cart", JSON.stringify(data));
    };

    return (
        <>
            <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
                <div className="offcanvas-header">
                    <h5 id="offcanvasRightLabel">Shopping Cart</h5>
                    <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div className="offcanvas-body">
                    {cartValue && cartValue.length > 0 ? (
                        <>
                            <div className='Product-addedCart-Side-Bar'>
                                {cartValue.map((elem, index) => (
                                    <div className='Product-addedCart-Side-details' key={index}>
                                        <div className='Product-addedCart-Image-con'>
                                            <div className='Product-addedCart-Image'>
                                                <img src={elem.productImage} alt={elem.productName} />
                                            </div>
                                        </div>
                                        <div className='Product-details-addedCart-price-name'>
                                            <h2>{elem.productName} ({elem.Weight &&
                                                elem.Weight > 999 &&
                                                `${(elem.Weight / 1000).toFixed(1)} kg`
                                            }
                                                {elem.Weight &&
                                                    elem.Weight <= 999 &&
                                                    `${elem.Weight} g`
                                                })</h2>
                                            <h3><span>Size : </span>{elem.Product_Width}'H' X {elem.Product_Height}'W' X {elem.Product_Length} 'L'</h3>
                                            <h3><span>Quantity : </span>{elem.productQuantity}</h3>
                                            <h3><span>Price : </span>{elem.fixedPrice} ₹</h3>
                                        </div>
                                        <div className='Product-details-addedCart-cancel-btn'>
                                            <div className='Product-details-addedCart-cancel-btn-con' onClick={() => removeCartData(index)}>
                                                <RxCross2 />
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className='Product-details-Addedcart-total-con'>
                                <div className='Product-details-Addedcart-total'>
                                    <div className='Product-details-Addedcart-total-title'>
                                        <h4>Subtotal:</h4>
                                    </div>
                                    <div className='Product-details-Addedcart-total-Amount'>
                                        <h5>{subTotal} ₹</h5> {/* Display Subtotal */}
                                    </div>
                                </div>
                                <div className='Product-details-Addedcart-btn'>
                                    <div className='Product-details-Addedcart-btn-ViewCart'>
                                        <Link to="/CheckOutPage">
                                            <button data-bs-dismiss="offcanvas" aria-label="Close">
                                                Checkout
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : <EmptyComp message="No data in cart" />}
                </div>
            </div>
        </>
    );
};

export default AddtoCart;
