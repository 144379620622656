import React, { useState } from 'react';
import { Button, Form, Input, Modal, Select, message } from 'antd';
import axios from 'axios';
import "../modal/AddressModal.css";

const { Option } = Select;

const AddressModal = ({ setSelectedAddress, selectedAddress }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const tokenid = sessionStorage.getItem("access_token");
    const [form] = Form.useForm();

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = async (values) => {
        console.log("values",values)
        try {
            const [stateCode, stateName] = values.state.split(" - ");
            const formDataToSend = new FormData();
            formDataToSend.append('alternative_number', values.alternative_number);
            formDataToSend.append('pincode', values.pincode);
            formDataToSend.append('address_1', values.address_1);
            formDataToSend.append('address_2', values.address_2);
            formDataToSend.append('city', values.city);
            formDataToSend.append('stateCode', stateCode);
            formDataToSend.append('stateName', stateName);
            formDataToSend.append('action', "addAddress");
            formDataToSend.append('token', tokenid);

            const response = await axios.post('https://jutiepie.in/api/address.php', formDataToSend);
            console.log('Response from API:', response);
            setIsModalOpen(false);
            message.success('Address added successfully');
            values.action = "1";
            values.avtive = response.data.address_id;
            setSelectedAddress(values);
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    return (
        <>
            <Button type="primary" onClick={showModal}>
                Add Address
            </Button>
            <Modal visible={isModalOpen} onCancel={handleCancel} footer={null}>
                <Form layout='vertical' onFinish={handleOk} form={form}>
                    <Form.Item
                        label="Mobile Number"
                        name="alternative_number"
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Pincode"
                        name="pincode"
                        rules={[
                            {
                                required: true,
                            }
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Address Line 1"
                        name="address_1"
                        rules={[
                            {
                                required: true,
                            }
                        ]}
                    >
                        <Input.TextArea />
                    </Form.Item>
                    <Form.Item
                        label="Address Line 2"
                        name="address_2"
                        rules={[
                            {
                                required: true,
                            }
                        ]}
                    >
                        <Input.TextArea />
                    </Form.Item>
                    <Form.Item
                        label="City"
                        name="city"
                        rules={[
                            {
                                required: true,
                            }
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="State"
                        name="state"
                        rules={[
                            {
                                required: true,
                                message: 'Please select your state!',
                            },
                        ]}
                    >
                        <Select
                            placeholder="Select State"
                            required
                        >
                            <Option value="01 - Jammu and Kashmir">01 - Jammu and Kashmir</Option>
                            <Option value="02 - Himachal Pradesh">02 - Himachal Pradesh</Option>
                            <Option value="03 - Punjab">03 - Punjab</Option>
                            <Option value="04 - Chandigarh">04 - Chandigarh</Option>
                            <Option value="05 - Uttarakhand">05 - Uttarakhand</Option>
                            <Option value="06 - Haryana">06 - Haryana</Option>
                            <Option value="07 - Delhi">07 - Delhi</Option>
                            <Option value="08 - Rajasthan">08 - Rajasthan</Option>
                            <Option value="09 - Uttar Pradesh">09 - Uttar Pradesh</Option>
                            <Option value="10 - Bihar">10 - Bihar</Option>
                            <Option value="11 - Sikkim">11 - Sikkim</Option>
                            <Option value="12 - Arunachal Pradesh">12 - Arunachal Pradesh</Option>
                            <Option value="13 - Nagaland">13 - Nagaland</Option>
                            <Option value="14 - Manipur">14 - Manipur</Option>
                            <Option value="15 - Mizoram">15 - Mizoram</Option>
                            <Option value="16 - Tripura">16 - Tripura</Option>
                            <Option value="17 - Meghalaya">17 - Meghalaya</Option>
                            <Option value="18 - Assam">18 - Assam</Option>
                            <Option value="19 - West Bengal">19 - West Bengal</Option>
                            <Option value="20 - Jharkhand">20 - Jharkhand</Option>
                            <Option value="21 - Odisha">21 - Odisha</Option>
                            <Option value="22 - Chattisgarh">22 - Chattisgarh</Option>
                            <Option value="23 - Madhya Pradesh">23 - Madhya Pradesh</Option>
                            <Option value="24 - Gujarat">24 - Gujarat</Option>
                            <Option value="26 - Dadra and Nagar Haveli and Daman and Diu">26 - Dadra and Nagar Haveli and Daman and Diu</Option>
                            <Option value="27 - Maharashtra">27 - Maharashtra</Option>
                            <Option value="29 - Karnataka">29 - Karnataka</Option>
                            <Option value="30 - Goa">30 - Goa</Option>
                            <Option value="31 - Lakshadweep">31 - Lakshadweep</Option>
                            <Option value="32 - Kerala">32 - Kerala</Option>
                            <Option value="33 - Tamil Nadu">33 - Tamil Nadu</Option>
                            <Option value="34 - Puducherry">34 - Puducherry</Option>
                            <Option value="35 - Andaman and Nicobar Islands">35 - Andaman and Nicobar Islands</Option>
                            <Option value="36 - Telangana">36 - Telangana</Option>
                            <Option value="37 - Andhra Pradesh">37 - Andhra Pradesh</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item>
                        <Button htmlType="submit">
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default AddressModal;
