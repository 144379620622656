import React from 'react';
import "../Css/Footer.css";
import { BiMailSend } from 'react-icons/bi';
import { FiMail } from 'react-icons/fi';
import { MdOutlineLocationOn } from 'react-icons/md';
import { IoCallOutline } from 'react-icons/io5';
import { SlSocialFacebook } from 'react-icons/sl';
import { BsWhatsapp, BsInstagram } from 'react-icons/bs';

import Logo from "../Images/Logo.png";
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <>
            <section className='Footer-Section'>
                <div className='container'>
                    <div className='Footer-Section-con'>
                        <div className='row'>
                            <div className='col-md-3'>
                                <div className='Footer-Logo-and-social-con'>
                                    <div className='Footer-Logo-con'>
                                        <div className='Footer-Logo'>
                                            <img src={Logo} />
                                        </div>
                                    </div>
                                    <div className='Footer-Para'>
                                        <p>
                                            Explore our products, connect with us on social media, or read our policies at the links below.
                                        </p>
                                    </div>
                                    <div className='Footer-Social-Con'>
                                        <div className='Footer-Social'>
                                            <a href="tel:+917304384413">
                                                <IoCallOutline />
                                            </a>
                                        </div>
                                        <div className='Footer-Social'>
                                            <a href="https://wa.me/+917304384413?text=" target='blank'>
                                                <BsWhatsapp />
                                            </a>
                                        </div>
                                        <div className='Footer-Social'>
                                            <a href='https://www.instagram.com/jutie_pie/' target='blank'>
                                                <BsInstagram />
                                            </a>
                                        </div>
                                        <div className='Footer-Social'>
                                            <a href="mailto: jutiepie99@gmail.com" target='blank'>
                                                <FiMail />
                                            </a>
                                        </div>
                                        <div className='Footer-Social'>
                                            <a href='https://www.facebook.com/jutiepiee/' target='blank'>
                                                <SlSocialFacebook />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-3'>
                                <div className='Footer-Quick-Link-con'>
                                    <h2>My Account</h2>
                                    <ul>
                                        <li>
                                            <Link to="/PrivacyPolicy">
                                                Privacy Policy
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/TermsConditions">
                                                Terms & Conditions
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/ReturnsPolicy">
                                                Refund & Returns Policy
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className='col-md-3'>
                                <div className='Footer-Quick-Link-con'>
                                    <h2>Quick Link</h2>
                                    <ul>
                                        <li>
                                            <Link to="/">
                                                Home
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/Product/all">
                                                Product
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/About">
                                                About
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/Contact">
                                                Contact
                                            </Link>
                                        </li>
                                    </ul>

                                </div>
                            </div>
                            <div className='col-md-3'>
                                <div className='Footer-contact-Details-con'>
                                    <div className='Footer-contact-Details-head'>
                                        <h2>
                                            Talk To Us
                                        </h2>
                                    </div>
                                    <div className='Footer-contact-Details-call'>
                                        <a>
                                            <IoCallOutline />
                                            <div className='Footer-contact'>
                                                <h2><a href="tel:+91 73043 84413">+91 73043 84413</a></h2>
                                                <h2><a href="tel:+91 87796 91413">+91 87796 91413</a></h2>
                                            </div>
                                        </a>
                                        <a href="mailto: jutiepie99@gmail.com">
                                            <BiMailSend />
                                            <h2>jutiepie99@gmail.com</h2>
                                        </a>
                                        <a href='https://maps.app.goo.gl/7qgtUGLAoD3WnLaj7' target='blank'>
                                            <MdOutlineLocationOn />
                                            <h2>22, Nalanda shopping centre, Bandu gore marg, Near goregaon station, Goregaon west, Mumbai 400104</h2>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='Footer-Section-Copy-con'>
                    <h2>Designed By <a href='https://skdm.in/' target='blank'> Shree Krishna Digital Marketing.</a></h2>
                    <h3>Copyright © 2024, All Right Reserved [ Jutie pie ]</h3>
                </div>
            </section>
        </>
    )
}

export default Footer