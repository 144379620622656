import React, { useEffect, useLayoutEffect, useState } from 'react';
import { AiFillHome } from 'react-icons/ai';
import { MdKeyboardArrowRight } from 'react-icons/md';
import "../Css/CheckOutPage.css";
import YourOrder from '../Component/checkout/YourOrder';
import Footer from '../Component/Footer';
import OrderDeatils from '../Component/checkout/OrderDeatils';
import OrderAddress from '../Component/checkout/OrderAddress';
import Orderlogin from '../Component/checkout/Orderlogin';
import Successbtn from '../Component/successandfailed/Successbtn';
import { useSelector } from "react-redux";
import PayOrder from '../Component/checkout/PayOrder';
import axios from 'axios';
const CheckOutPage = () => {
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });
    const [selectedAddress, setSelectedAddress] = useState(null);
    const tokenid = sessionStorage.getItem("access_token")
    const fetchData = async () => {
        try {
            const response = await axios.get('https://jutiepie.in/api/address.php?&action=fetchAddresses&token=' + tokenid);
            const addressListData = response.data.data;
            const selected = addressListData.find(address => address.action === "1")
            setSelectedAddress(selected);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };


    useEffect(() => {
        fetchData();
    }, [tokenid]);

    const { token } = useSelector((state) => state.auth);

    return (
        <>
            {/* ---------------- Section 1 ---------------------- */}
            <section className='CheckOutPage-Section-1'>
                <div className='container'>

                    <div className='CheckOutPage-Section-con'>
                        <div className='CheckOutPage-Section-Text'>
                            <h2>
                                Check Out
                            </h2>
                            <div className='CheckOutPage-Section-NavPage'>
                                <span><AiFillHome />Home</span>
                                <h3><MdKeyboardArrowRight /></h3>
                                <h1 className="d-none">Best Salon in Ghatkopar</h1>
                                <h2>Check Out</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* ---------------- Section 2 ---------------------- */}

            <section className='CheckOutPage-Section-2'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-7'>
                            {token ? (
                                <>
                                    <OrderDeatils />
                                    <OrderAddress selectedAddress={selectedAddress} setSelectedAddress={setSelectedAddress} />
                                </>
                            ) : (
                                <Orderlogin />
                            )
                            }
                        </div>
                        <div className='col-md-5'>
                            <PayOrder selectedAddress={selectedAddress}/>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default CheckOutPage